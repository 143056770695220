<template>
  <v-container fluid>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Layer</th>
            <th class="text-left">Opacidade</th>
            <th class="text-left">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="layer in layers"
            :key="layer.id"
          >
            <td style="max-width: 80px">
              {{ layer.nome }}
            </td>
            <td>
              <v-slider
                :thumb-size="24"
                :value="layer.opacidade"
                thumb-color="#7CB342"
                thumb-label="always"
                @change="(event) => setOpacidade(layer, event)"
              />
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    min-width="0"
                    icon
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="removeLayer(layer)"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#2B60D6"
                    min-width="0"
                    icon
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="() => (dialogAddLayer = true)"
                  >
                    <v-icon small> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Adicionar</span>
              </v-tooltip>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <dialog-seletor-layer
      :activated.sync="dialogAddLayer"
      @selected="setLayer($event)"
    />
  </v-container>
</template>

<script>
export default {
  components: {
    DialogSeletorLayer: () =>
      import('@/components/general/seletores/DialogSeletorLayer.vue')
  },

  data() {
    return {
      dialogAddLayer: false
    };
  },

  computed: {
    layers() {
      return this.$store.getters.getCanvasConfigLayers;
    }
  },

  methods: {
    setLayer(layer) {
      this.addLayer(layer);
      this.dialogAddLayer = false;
    },

    addLayer(layer) {
      const layerExiste = this.layers.filter(
        (item) => item.id === layer.id
      ).length;
      if (!layerExiste) {
        this.layers.push({ ...layer, opacidade: 100 });
      } else {
        this.$toast.error(
          `O layer ${layer.nome} já está na lista de layers selecionados.`,
          '',
          { position: 'topRight' }
        );
      }
    },

    removeLayer(layer) {
      let layers = [...this.layers];
      const index = this.layers.findIndex((item) => item.id === layer.id);
      layers.splice(index, 1);
      this.setLayers(layers);
    },

    setOpacidade(layer, opacidade) {
      let layers = [...this.layers];
      const index = this.layers.findIndex((item) => item.id === layer.id);
      layers[index].opacidade = opacidade;
      this.setLayers(layers);
    },

    setLayers(layers) {
      this.$store.dispatch('setCanvasConfigLayers', layers);
    }
  }
};
</script>
